
import "deep-chat";
import { Component, Vue, Watch } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { Testata } from "@/models/testata.model";
import { AdminCookie,  CategoriesGiurisprudenza,  CategoriesNormativa,  CategoriesPrassiJuranet,  Sections } from "@/models/enums.model";
import MetaInfo from "vue-meta";
import JnProgress from "@/views/common/Progress.vue";
import JnPaywall from "@/views/auth/Paywall.vue";
import { BaseAnalysis, PaginatedAnalysis, BaseMaximsAnalysis, BaseSummarizationGenericAnalysis, BaseCassationSentenceAnalysis, Feedback } from "@/models/ai/document.analysis.model";
import { JnAiService } from "@/services/ai.service";
import EventBus from "@/services/event.bus";
import { SearchData } from "@/models/search.model";
import { EsService } from "@/services/es.service";
import { Document } from "@/models/document.model";
import { Picklist } from "@/models/picklist.model";

@Component({
  components: {
    JnPaywall,
    JnProgress
  },
  metaInfo(this: JnAiAnalysisReview): MetaInfo {
    return { title: "JuraNews - AI Analysis Review" };
  },
})
export default class JnAiAnalysisReview extends Vue {
  testata?: Testata;

  jwtToken = "";

  admin = false;

  // Filtri
  categorie: Picklist[] = [];
  optCategorie: Picklist[] = [];
  verificationStatus: Picklist[] = [];
  optVerified?: Picklist
  optAnalysisType: Picklist[] = [];
  analysisType: Picklist[] = [];

  // Loading e alerting
  loading = false
  alert = false
  alertMessage = ""
  alertTimeout = 8000

  // Filtri
  idJuraNews: string | undefined = ""
  idJuranet: string | undefined = ""

  paginatedAnalysis = {} as PaginatedAnalysis

  itemsPerPage = 8
  page = 1

  selectedIndex = -1;
  selectedAnalysis = {} as BaseAnalysis
  feedbacks: Feedback[] = []
  expandedMaximsPanels = [0]
  expandedCassationSentencePanels = [0]

  // Paginazione
  async onPageChange(newPage: number) {
    this.page = newPage;
    await this.loadAnalysis()
    this.selectedIndex = -1;
  }
  totalPages() {
    return Math.ceil(this.paginatedAnalysis.total_items / this.itemsPerPage);
  }

  async analysisClicked(item: BaseAnalysis, index: number) {
    this.loading = true
    this.selectedIndex = index
    this.selectedAnalysis = item
    try {
      const doc = await this.getDocument(item.juranews_id,  item.juranet_id ? "juranet" : "juranews", true)
      if (doc) {
        item.doc.text = doc.text
      }
      this.feedbacks = await JnAiService.getFeedbacks(item.id, this.jwtToken)
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nella cancellazione della conversazione";
        this.alert = true;
      }
    }
    finally {
      this.loading = false
    }
  }

  // Request & Response interceptors
  async getDocument(id: string, source: string, withText: boolean): Promise<Document | undefined> {
    const searchData = new SearchData({
      index: source == "juranet" ? process.env.VUE_APP_JURANET_INDEXES! : process.env.VUE_APP_JURANEWS_INDEXES!,
      id: id,
      size: 1,
      from: 0,
      withText: withText,
    });
    try {
      const document = await EsService.getDoc(searchData, this.jwtToken)
      return document
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        EventBus.$emit("check-authentication")
      } else { 
        this.alertMessage = "Errore nel caricamento del documento '" + id + "': '" + error;
        this.alert = true;
      }
    }
  }
  
  getAnalysisType(analysis: BaseAnalysis) {
    if (analysis.prompt_template == "legal.cassation_sentence_analyzer" ) {
      return "SINTESI CASSAZIONE";
    }
    if (analysis.prompt_template == "legal.summarization_generic" ) {
      return "SINTESI GENERICA";
    }
    else if (analysis.prompt_template == "legal.maximizer") {
      return "MASSIME";
    }
  }

  getAverageRating() {
    let sum = 0;
    for (const feedback of this.feedbacks) {
      sum += feedback.rating;
    }
    return (sum / this.feedbacks.length).toFixed(2);
  }

  async update() {
    this.loading = true
    try {
      if (this.selectedAnalysis) {
        this.selectedAnalysis.updating = true
        await JnAiService.updateDocumentAnalysis(this.selectedAnalysis, this.jwtToken)
        this.alertMessage = "Analisi aggiornata con successo";
        this.alert = true;
      }
    } catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.admin = false
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nel caricamento";
        this.alert = true;
      }
    }
    finally {
      this.loading = false;
    }
  }

  async approve() {
    this.loading = true
    try {
      if (this.selectedAnalysis) {
        this.selectedAnalysis.updating = false
        this.selectedAnalysis.verified = true
        await JnAiService.updateDocumentAnalysis(this.selectedAnalysis, this.jwtToken)
        await this.loadAnalysis()
        this.alertMessage = "Analisi aggiornata con successo";
        this.alert = true;        
      }
    } catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.admin = false
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nel caricamento";
        this.alert = true;
      }
    }
    finally {
      this.loading = false;
    }
  }

  asMaxims(analysis: BaseAnalysis): BaseMaximsAnalysis {
    return analysis as BaseMaximsAnalysis
  }
  asSummaryGeneric(analysis: BaseAnalysis): BaseSummarizationGenericAnalysis {
    return analysis.analysis as BaseSummarizationGenericAnalysis
  }
  asCassationSentenceAnalysis(analysis: BaseAnalysis): BaseCassationSentenceAnalysis {
    return analysis.analysis as BaseCassationSentenceAnalysis
  }

  async loadAnalysis() {
    this.loading = true;
    this.selectedIndex = -1;
    this.selectedAnalysis = {} as BaseAnalysis;
    try {
      const verified = this.optVerified ? (this.optVerified.value == "both" ? undefined : (this.optVerified.value == "reviewed" ? true : false)) : undefined
      let categories: string[] = []
      for (const cat of this.optCategorie) {
        if (cat.value == "1") {
          categories = [...categories, ...CategoriesNormativa];
        }
        if (cat.value == "2") {
          categories = [...categories, ...CategoriesGiurisprudenza];
        }
        if (cat.value == "3") {
          categories = [...categories, ...CategoriesPrassiJuranet];
        }
      }
      const promptTemplates: string[] = []
      for (const anType of this.optAnalysisType) {
        promptTemplates.push(anType.value)
      }
      const categoriesInt = categories.map(cat => Number(cat));
      this.paginatedAnalysis = await JnAiService.getPaginatedAnalysis(
                                            this.page,
                                            this.itemsPerPage,
                                            verified,
                                            categoriesInt,
                                            this.isNumeric(this.idJuraNews) ? Number(this.idJuraNews) : undefined,
                                            this.isNumeric(this.idJuranet) ? Number(this.idJuranet) : undefined,
                                            promptTemplates,
                                            this.jwtToken);
      for (const item of this.paginatedAnalysis.analysis) {
        const doc = await this.getDocument(item.juranews_id, item.juranet_id ? "juranet" : "juranews", false)
        if (doc) {
          item.doc = doc
        }
      }
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.admin = false
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nel caricamento";
        this.alert = true;
      }
    }
    finally {
      this.loading = false;
    }
  }
  
  
  searchJuraNewsId() {
    if (this.isNumeric(this.idJuraNews)) {
      this.loadAnalysis()
    }
    else {
      this.alertMessage = "Inserisci un valore numerico";
      this.alert = true;
    }
  }

  searchJuranetId() {
    if (this.isNumeric(this.idJuranet)) {
      this.loadAnalysis()
    }
    else {
      this.alertMessage = "Inserisci un valore numerico";
      this.alert = true;
    }
  }

  // Validation rules
  numericRule(value: string | undefined) {
    const pattern = /^[0-9]*$/;
    return value && pattern.test(value) || 'Il valore deve essere un numero';
  }
  isNumeric(value: string | undefined) {
    if (value) {
      return /^[0-9]+$/.test(value);
    }
    return false;
  }

  // Lyfecycle
  async initCombo() {
    const categorieRaw = JSON.parse(
      await EsService.getOptions('categorie-banca-dati-juranet')
    );
    for (const c of categorieRaw) {
      if (!c.id.includes('|')) {
        this.categorie.push(new Picklist({ value: c.id, label: c.value }));
      }
    }
    this.verificationStatus.push(new Picklist({ value: "both", label: "Tutti" }));
    this.verificationStatus.push(new Picklist({ value: "reviewed", label: "Revisionati" }));
    this.verificationStatus.push(new Picklist({ value: "unreviewed", label: "Non revisionati" }));

    this.analysisType.push(new Picklist({ value: "legal.maximizer", label: "Massime" }));
    this.analysisType.push(new Picklist({ value: "legal.cassation_sentence_analyzer", label: "Sintesi Cassazione" }));
    this.analysisType.push(new Picklist({ value: "legal.summarization_generic", label: "Sintesi Generica" }));

    this.optCategorie = this.categorie;
    this.optAnalysisType = this.analysisType;
    this.optVerified = this.verificationStatus[2]
  }

  async created() {
    this.testata = JnService.getTestata(Sections.AI_REVIEW);
  }


  async mounted() {
    this.loading = true;
    try {
      EventBus.$on("logged-paywall-ok", async () => {
        this.jwtToken = String(this.$cookies.get("jwtToken"));
        this.admin = this.$cookies.get(AdminCookie) === "true";
        await this.loadAnalysis()  
        
      });
      EventBus.$on("logged-paywall-not-ok", () => {
        EventBus.$emit("check-authentication")
        this.admin = false
      });

      this.admin = this.$cookies.get(AdminCookie) === "true";
      await this.initCombo()

      const usernameFromCookie = this.$cookies.get("username")!;
      this.jwtToken = String(this.$cookies.get("jwtToken"));
      if (usernameFromCookie && this.jwtToken) {
        await this.loadAnalysis()
      }
    }
    catch (error) {
      const err = error as any
      if (err.response && err.response.status === 401) {
        this.admin = false
        EventBus.$emit("check-authentication")
      } else {
        this.alertMessage = "Errore nel caricamento";
        this.alert = true;
      }
    }
    finally {
      this.loading = false;
    }
  }

}

